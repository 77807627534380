import {
    Button,
    Container,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core';
import { Check, Visibility, VisibilityOff } from '@material-ui/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { axiosInstance } from 'axiosInstance';
import { API_ROUTES } from 'constants/api';
import { PASSWORD_REGEX } from 'constants/regex';
import {
    resetPassword,
    resetUpdatePasswordState,
    selectUpdatePasswordStatus,
} from 'slices/authSlice';
import { useAppDispatch } from 'store';
import { PageContainer, PaperContainer } from 'styles/ReusabledStyledComponents';
import { SliceStatus } from 'types';

const Form = styled.form`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

interface NavParams {
    token?: string;
}

type FormData = {
    newPassword: string;
    confirmPassword: string;
};

const CURRENT_PAGE = 'CreatePasswordPage';

const CreatePasswordPage: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const appDispatch = useAppDispatch();
    const { token } = useParams<NavParams>();

    const updateStatus = useSelector(selectUpdatePasswordStatus);
    const isLoading = useSelector(selectUpdatePasswordStatus) === SliceStatus.LOADING;

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm<FormData>();

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Void return.
        verifyToken(token);
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Not needed
    }, [token]);

    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const newPassword = useWatch({ defaultValue: '', control, name: 'newPassword' });
    const confirmPassword = useWatch({ defaultValue: '', control, name: 'confirmPassword' });

    const [newPasswordValid, setNewPasswordValid] = React.useState(false);
    const [confirmPasswordValid, setConfirmPasswordValid] = React.useState(false);

    const toggleNewPasswordVisibility = () => setShowNewPassword((prev) => !prev);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword((prev) => !prev);

    useEffect(() => {
        setNewPasswordValid(PASSWORD_REGEX.test(newPassword));
    }, [newPassword]);

    useEffect(() => {
        setConfirmPasswordValid(newPasswordValid && newPassword === confirmPassword);
    }, [newPasswordValid, newPassword, confirmPassword]);

    const verifyToken = React.useCallback(
        async (createPasswordToken: string | undefined) => {
            if (!createPasswordToken) {
                history.replace('/');
                return;
            }

            await axiosInstance
                .post(API_ROUTES.VERIFY_CREATE_PASSWORD_TOKEN, {
                    token: createPasswordToken,
                })
                .catch(() => {
                    history.replace('/');
                });
        },
        [history],
    );

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        if (!data.newPassword || !data.confirmPassword || !token) {
            return;
        }

        const payload = {
            password: data.newPassword,
            token,
        };

        await appDispatch(resetPassword(payload))
            .then(unwrapResult)
            .catch((_) => {
                setErrorDialogTextKey('Errors.Generic');
                setOpenErrorDialog(true);
            });
    };

    const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
    const [errorDialogTextKey, setErrorDialogTextKey] = React.useState<string>('');

    const handleErrorDialogClose = () => {
        dispatch(resetUpdatePasswordState);
        setOpenErrorDialog(false);
    };

    return (
        <>
            {updateStatus === SliceStatus.SUCCEDED && <Redirect to={'/password-changed'} />}
            <Helmet>
                <title>{t(`${CURRENT_PAGE}.helmetTitle`)}</title>
            </Helmet>
            <PageContainer data-testid="create_password_page">
                <PaperContainer square={true}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Typography>{t(`${CURRENT_PAGE}.title`)}</Typography>
                        <Controller
                            name="newPassword"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: true,
                                minLength: 6,
                                validate: (value) => PASSWORD_REGEX.test(value),
                            }}
                            render={({ field }) => (
                                <TextField
                                    label={t(`${CURRENT_PAGE}.newPasswordLabel`)}
                                    style={{ width: '100%' }}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                {newPasswordValid && <Check />}
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={toggleNewPasswordVisibility}
                                                    >
                                                        {showNewPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                    error={errors.newPassword !== undefined}
                                    helperText={
                                        errors.newPassword !== undefined && t('Global.required')
                                    }
                                    type={showNewPassword ? 'text' : 'password'}
                                    required
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="confirmPassword"
                            control={control}
                            defaultValue=""
                            rules={{ required: true, validate: (value) => value === newPassword }}
                            render={({ field }) => (
                                <TextField
                                    label={t(`${CURRENT_PAGE}.confirmPasswordLabel`)}
                                    style={{ width: '100%' }}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                {confirmPasswordValid && <Check />}
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={toggleConfirmPasswordVisibility}
                                                    >
                                                        {showConfirmPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                    error={errors.confirmPassword !== undefined}
                                    helperText={
                                        errors.confirmPassword !== undefined && t('Global.required')
                                    }
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    required
                                    {...field}
                                />
                            )}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{ marginTop: 32 }}
                            disabled={!isValid || isLoading}
                        >
                            {t(`${CURRENT_PAGE}.submitButton`)}
                        </Button>
                    </Form>
                    <Container style={{ marginTop: 32 }}>
                        <p style={{ margin: 0 }}>
                            {' '}
                            Le mot de passe doit contenir un minimum de 6 caractères et:
                        </p>
                        <ul style={{ textAlign: 'left' }}>
                            <li>1 lettre minuscule</li>
                            <li>1 lettre majuscule</li>
                            <li>1 chiffre</li>
                            <li>1 de ces symboles (@$!%*?&)</li>
                        </ul>
                    </Container>
                </PaperContainer>
            </PageContainer>
            <Dialog
                open={openErrorDialog}
                onClose={handleErrorDialogClose}
                aria-labelledby="create-user-sheet-error-dialog"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t(errorDialogTextKey)}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CreatePasswordPage;
